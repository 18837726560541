<template>
  <div>
    <div v-if="isFetching">
      <div class="gray-box">
        <h6 class="pb-1 pl-3 fw-600">Eligibility Matched</h6>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Eligibility Country</CCol>
          <CCol lg="8" class="text-black">{{
            pqrLevel.country_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Authority</CCol>
          <CCol lg="8" class="text-black">{{
            pqrLevel.issue_authority_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Suitable For</CCol>
          <CCol lg="8" class="text-black">{{ eligibility_level || "--" }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Eligibility Requirement</CCol>
          <CCol lg="8" class="text-black">{{ pqrLevel.comments || "--" }}</CCol>
        </CRow>
        <CRow class="profileView" v-if="pqrLevel.potential.length">
          <CCol lg="4" class="text-gray">Note</CCol>
          <CCol lg="8" class="text-black"
            >{{ pqrLevel.potential[0].pqr_detail_description }} in next 6
            months</CCol
          >
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Date Created</CCol>
          <CCol lg="8" class="text-black">{{
            pqrLevel.created_on | moment
          }}</CCol>
        </CRow>
      </div>
      <div v-if="qualificationDetail" class="gray-box">
        <h6 class="pb-1 fw-600 pl-3 pt-2 pb-2">Qualification Matched</h6>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Qualification</CCol>
          <CCol lg="8" class="text-black">{{
            qualificationDetail.qualification_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Qualification Country</CCol>
          <CCol lg="8" class="text-black">{{
            qualificationDetail.country_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Speciality</CCol>
          <CCol lg="8" class="text-black"
            >{{ qualificationDetail.speciality_name || "--" }}
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Issue Authority</CCol>
          <CCol lg="8" class="text-black">{{
            qualificationDetail.issue_authority_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Year Awarded</CCol>
          <CCol lg="8" class="text-black">{{
            qualificationDetail.month_year_awarded || "--"
          }}</CCol>
        </CRow>
      </div>
      <div v-if="licenseDetail" class="gray-box">
        <h6 class="pb-1 fw-600 border-top pt-2 pb-2">License Matched</h6>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">License Country</CCol>
          <CCol lg="8" class="text-black">{{
            licenseDetail.country_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Licensing Authority</CCol>
          <CCol lg="8" class="text-black">{{
            licenseDetail.licensing_authority_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Issue Date</CCol>
          <CCol lg="8" class="text-black">{{
            licenseDetail.issue_date | moment
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Is Specialist Registration?</CCol>
          <CCol lg="8" class="text-black">{{
            licenseDetail.specialist_registration | yesOrNo
          }}</CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4" class="text-gray">Is License Current</CCol>
          <CCol lg="8" class="text-black">{{
            licenseDetail.current_license || "--"
          }}</CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: ["pqrLevel", "profile"],
  computed: {
    isNurse() {
      return this.profile.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.profile.candidate_type_id === 3 || false;
    },
    isFetching() {
      return isObject(this.pqrLevel) && !isEmptyObjectCheck(this.pqrLevel);
    },
    qualificationDetail() {
      if (this.isFetching) {
        if (
          isObject(this.pqrLevel.candidate_qualification) &&
          !isEmptyObjectCheck(this.pqrLevel.candidate_qualification)
        ) {
          let {
            qualification_name,
            country_name,
            speciality_name,
            issue_authority_name,
            month_awarded,
            year_awarded,
          } = this.pqrLevel.candidate_qualification;
          return {
            qualification_name,
            country_name,
            speciality_name,
            issue_authority_name,
            month_year_awarded: `${this.getMonth(
              month_awarded
            )} ${year_awarded}`,
          };
        }
      }
      return false;
    },
    licenseDetail() {
      if (this.isFetching) {
        if (
          isObject(this.pqrLevel.candidate_license) &&
          !isEmptyObjectCheck(this.pqrLevel.candidate_license)
        ) {
          let {
            country_name,
            licensing_authority_name,
            issue_date,
            specialist_registration,
            current_license,
          } = this.pqrLevel.candidate_license;
          return {
            country_name,
            licensing_authority_name,
            issue_date,
            specialist_registration,
            current_license,
          };
        }
      }
      return false;
    },
    eligibility_level() {
      if(this.isAHP){
        const country_code = this.pqrLevel?.pqr_ahp_detail?.pqr_header?.country?.country_code;
        const level = this.pqrLevel.pqr_ahp_detail?.speciality?.speciality;
        return country_code && level ? `${level}(${country_code})` : level;
      }
      const country_code = this.pqrLevel?.pqr_detail?.pqr_header?.country?.country_code;
      const level = this.pqrLevel.working_candidate_level;
      return country_code && level ? `${level}(${country_code})` : level;
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
    yesOrNo: function (data) {
      if (data != null && typeof data === "boolean") return data ? "Yes" : "No";
      return "--";
    },
  },
  methods: {
    getMonth(date) {
      if (date) return moment.monthsShort()[date - 1];
      return "";
    },
  },
};
</script>