<template>
  <div>
    <div v-if="isCVFound">
      <div
        v-if="iframe.loaded && !iframe.isImg && !iframe.pdf.ispdf"
        class="embed-responsive embed-responsive-16by9 z-depth-1-half"
      >
        <iframe
          id="dociframe"
          class="embed-responsive-item"
          :src="iframe.src"
          v-show="iframe.loaded"
        ></iframe>
      </div>
      <div
        v-else-if="iframe.isImg && !iframe.loaded && !iframe.pdf.ispdf"
        class="d-flex justify-content-center modalPdfContent"
      >
        <img
          :src="iframe.src"
          v-show="iframe.isImg"
          class="iframeclass"
          id="iframeimg"
        />
      </div>
      <div
        v-else-if="iframe.pdf.ispdf && !iframe.isImg && !iframe.loaded"
        class="wrapper modalPdfContent"
      >
        <pdf
          v-for="i in iframe.pdf.numPages"
          :key="i"
          :src="iframe.pdf.src"
          :page="i"
          style="overflow-y: auto"
        ></pdf>
      </div>
    </div>
    <div v-else>
      <h4 class="text-muted text-center mt-3">No CV / Resume Found</h4>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import { BASE_URL } from "@/service_urls";
import pdf from "vue-pdf";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
export default {
  data() {
    return {
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
    };
  },
  components: { pdf },
  computed: {
    ...mapGetters(["getResumeDocument"]),
    isCVFound() {
      if (
        isObject(this.getResumeDocument) &&
        !isEmptyObjectCheck(this.getResumeDocument)
      ) {
        let _baseUrl = BASE_URL;
        // let _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
        let _base_domain = "https://" + window.location.host;
        let data = this.getResumeDocument;
        data.link_to_document = this.showPreview(data);
        if (["PNG", "JPG", "JPEG", "GIF"].includes(data?.document_ext)) {
          this.iframe.loaded = false;
          this.iframe.ispdf = false;
          this.iframe.isImg = true;
          this.iframe.src = _base_domain + data.link_to_document;
          // this.previewModal.isPreview = true;
          // this.previewModal.isShowPopup = true;
        } else if (data?.document_ext === "PDF") {
          this.iframe.isImg = false;
          this.iframe.loaded = false;
          this.iframe.pdf.ispdf = true;
          this.iframe.pdf.src = pdf.createLoadingTask(data.link_to_document);
          setTimeout(() => {
            this.iframe.pdf.src.promise.then((pdf) => {
              this.iframe.pdf.numPages = pdf.numPages;
            });
          }, 100);
        } else if (
          ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data?.document_ext)
        ) {
          this.showToast({
            class: "bg-danger text-white",
            message: "The selected file type is not available for Preview.",
          });
          this.iframe.src = "";
          this.iframe.loaded = false;
          this.iframe.isImg = false;
          this.iframe.pdf.ispdf = false;
        } else {
          this.iframe.isImg = false;
          this.iframe.ispdf = false;
          this.iframe.loaded = true;
          this.iframe.src =
            "https://view.officeapps.live.com/op/embed.aspx?src=" +
            data.link_to_document;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    showPreview(data) {
      const { link } = data;
        // const _baseUrl = BASE_URL;
        // const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
       const _base_domain = "https://" + window.location.host;
      return appendAccessToken(`${_base_domain}${link}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.iframeclass {
  width: 75%;
}
.modalPdfContent {
  height: 350px !important;
  overflow-y: scroll;
}
.wrapper {
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
@media all and (max-width: 480px) {
  .iframeclass {
    width: auto;
  }
}
</style>
