<template>
  <div>
    <div v-if="isFetched">
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Qualification Name</CCol>
        <CCol lg="7" class="text-black">{{ qualificationName(qualification) }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Subject</CCol>
        <CCol lg="7" class="text-black">{{ qualification.others_name }}</CCol>
      </CRow>

      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Score</CCol>
        <CCol lg="7" class="text-black">{{ qualificationScore(qualification) }}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Skill Level</CCol>
        <CCol lg="7" class="text-black">{{ qualification.skill_level || "--"}}</CCol>
      </CRow>
      <CRow class="profileView">
        <CCol lg="5" class="text-gray">Qualified Month/Year</CCol>
        <CCol lg="7" class="text-black">{{ formatDate(qualification.month, qualification.year) }}
          <span v-if="qualification.expired_message" class="data text-primary" :style="{'font-size':'14px'}">{{ '(' + qualification.expired_message + ')' }}</span>
        </CCol>
      </CRow>
    </div>  
  </div>
</template>
<script>
import m from "moment"
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: ["qualification"],
  computed: {
    isFetched() {
      return (
        isObject(this.qualification) && !isEmptyObjectCheck(this.qualification)
      );
    },
  },
  methods: {
    qualificationName(data) {
      if (data !== null)
        if (data.qual_type_id == 2 || data.qual_type_id == 3) {
          let qual_name = data.name
          return qual_name
        }
        return data.name !== "--"
          ? data.name
          : `Other${
              data.others_name
                ? " - " + data.others_name
                : ""
            }`;
      return "--";
    },
    qualificationScore(data) {
      if (data !== null)
        return data.score !== "N/A"
          ? `${data.score ? data.score : "--"}`
          : "--" ;
      return "--";
    },
    formatDate(month, year) {
      if (month && year) {
        let date = month ? new Date(`${year},${month},01`) : "--";
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
  }
}
</script>